// extracted by mini-css-extract-plugin
export var benefitsCustomSoftwareSection = "K_hj";
export var businessCustomSoftwareSection = "K_hg";
export var caseStudyCustomSoftwareSection = "K_hl";
export var casesCustomSoftwareSection = "K_hn";
export var clientQuotesCustomSoftwareSection = "K_hw";
export var customSoftwareProjLogoSection = "K_hq";
export var expertiseCustomSoftwareSection = "K_hr";
export var industriesCustomSoftwareSection = "K_hf";
export var ourAchievementsCustomSoftwareSection = "K_hv";
export var processCustomSoftwareSection = "K_hh";
export var requirementCustomSoftwareSection = "K_hm";
export var servicesCustomSoftwareSection = "K_hd";
export var techStackCustomSoftwareSection = "K_hk";
export var testimonialsCustomSoftwareSection = "K_hs";
export var testimonialsCustomSoftwareSection__testimonial = "K_ht";
export var whyChooseCustomSoftwareSection = "K_hp";